/*
 * 업무구분: 고객
 * 화 면 명: MSPCM004M
 * 화면설명: 고객인증목록
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.06.09
 * 작 성 자: 이태훈
 * 2차 작성자: sh.park2022
 */
<template>
  <ur-page-container title="본인인증" :show-title="true" type="subpage" class="msp" @on-header-left-click="fn_Back">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container direction="column" alignV="start" componentid="ur_box_container_000" class="ns-verify">

        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-radio-list w100">
          <mo-radio v-model="radioSelect" value="카카오" @click="fn_MoveMspcm020mPage('1')" class="w100">카카오</mo-radio>
          <mo-radio v-model="radioSelect" value="휴대폰" @click="fn_MoveMspcm020mPage('2')" class="w100">휴대폰</mo-radio>
          <mo-radio v-model="radioSelect" value="신용카드" @click="fn_MoveMspcm020mPage('3')" class="w100">신용카드</mo-radio>
        </ur-box-container>

      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
      // 현재 화면명
      name: 'MSPCM004M',
      // 현재 화면 ID
      screenId: 'MSPCM004M',
      components: {
      },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        title: '고객인증목록', // 화면명
        agreeTypeMcnst: false, // 필수컨설팅 동의 여부
        mktChkList: [], //마컨 정보(광고성 수신동의)['P', 'S', 'M', 'E']
        mktCnsntAvlPrd : '', //개인(신용)정보 보유이용기간 선택
        infoPrcusCnsntTypCd: '', // 정보활용동의 유형코드(17: 필수컨설팅, 19: 마케팅동의, 18: 둘다)
        segmentType1: '', //마케팅 (거래, 미거래)
        authSlctPage: '', //인증 목록 선택 페이지
        radioSelect: '',
        
        pCustNm: ''
      }
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    /** computed 함수 정의 영역 */
    computed: {
    },
    
    /** watch 정의 영역 */
    watch: {
    },

    /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
    created() {
      this.pCustNm = !this.$bizUtil.isEmpty(this.$route.params.custNm) ? this.$route.params.custNm : ''
      if(!_.isEmpty(this.$route.params)){
        this.agreeTypeMcnst = this.$route.params.agreeTypeMcnst //필컨 정보 여부
        this.mktChkList = this.$route.params.mktChkList //마컨 정보(광고성 수신동의)
        this.segmentType1 = this.$route.params.segmentType1 
        this.mktCnsntAvlPrd = this.$route.params.mktCnsntAvlPrd //마컨 정보(개인정보 보유이용기간)
        this.infoPrcusCnsntTypCd =  this.$route.params.infoPrcusCnsntTypCd //필컨만 : 17, 마동만 : 19, 필컨&마동 : 18
      } else {
        // 고객 정보
        let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo

        this.infoPrcusCnsntTypCd = storeCustInfo.infoPrcusCnsntTypCd
        // 필동
        this.agreeTypeMcnst = storeCustInfo.agreeTypeMcnst

        // 마동
        if (!_.isEmpty(storeCustInfo.mktChkList)) {
          this.mktChkList = storeCustInfo.mktChkList
          this.segmentType1 = storeCustInfo.segmentType1
          this.mktCnsntAvlPrd = storeCustInfo.mktCnsntAvlPrd

          this.pCustNm = storeCustInfo.custNm
        }
      }

      this.$BottomManager.fn_SetBottomVisible(false)

      // backButton event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_Back)
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPCM004M')

      // style Class 추가
      let spanList = document.getElementById('scroll_MSPCM004M').getElementsByTagName('span')

      for(let i=0; i<spanList.length; i++) {
        spanList[i].classList.add('full')
      }
    },
    beforeDestroy() {
      this.$BottomManager.fn_SetBottomVisible(true)
      
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_Back)
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_MoveMspcm020mPage
      * 설명       : MSPCM020M화면으로 이동 (인증 메인화면)
      ******************************************************************************/
      fn_MoveMspcm020mPage (mPage) {
        // 현재 화면을 history 배열에 저장
        // this.$commonUtil.setLastScreen(this.$options.screenId)

        switch(mPage){
          case '1':
            this.authSlctPage = 'Kakao'
          break
          case '2' :
            this.authSlctPage = 'MobilePhone'
          break
          case '3' :
            this.authSlctPage = 'CreaditCard'
          break
        }
        let lv_Vm = this
        window.setTimeout( () => {
          // Store 페이지 카운트
          let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
          window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)

          let pParam = {
                name: 'MSPCM004M',
                agreeTypeMcnst: lv_Vm.agreeTypeMcnst, //필컨 정보
                mktChkList: lv_Vm.mktChkList, //마컨 정보(광고성 수신동의)
                mktCnsntAvlPrd: lv_Vm.mktCnsntAvlPrd, //마컨 정보(개인정보 보유이용기간)
                infoPrcusCnsntTypCd: lv_Vm.infoPrcusCnsntTypCd, //필컨만 : 17, 마동만 : 19, 필컨, 마동 둘다 : 18
                authSlctPage: lv_Vm.authSlctPage, //(Kakao:카카오, MobilePhone:휴대폰, CreaditCard:신용카드)
                custNm: lv_Vm.pCustNm
              }

          // 고객 정보 저장
          let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo
          storeCustInfo = Object.assign(storeCustInfo, pParam)
          window.vue.getStore('cmStore').dispatch('ADD', storeCustInfo)

          lv_Vm.$router.push(
            {
              name: 'MSPCM020M',
              params: pParam
            }
          )
        }, 500)
      },
      /******************************************************************************
       * Function명: fn_Back
       * 설명: 헤더 영역 Back Button 파라미터 넘기기
       * Params: N/A
       * Return: N/A
       ******************************************************************************/
      fn_Back () {
        let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt-1)
        this.$router.go(-1)
        return
        let lv_BfPageId = 'MSPCM003M'
        if(this.infoPrcusCnsntTypCd === '17'){
          lv_BfPageId = 'MSPCM002M'
        }
        // this.$commonUtil.setLastScreen(this.$options.screenId)

        this.$router.push(
          {
            name: lv_BfPageId, // 이동할 페이지
            params: {
              name: 'MSPCM004M', // 현재 페이지
              agreeTypeMcnst: this.agreeTypeMcnst, //필컨 정보
              mktChkList: this.mktChkList, //마컨 정보(광고성 수신동의)
              mktCnsntAvlPrd: this.mktCnsntAvlPrd, //마컨 정보(개인정보 보유이용기간)
              segmentType1: this.segmentType1, // 마컨 정보(거래여부)
              infoPrcusCnsntTypCd:  this.infoPrcusCnsntTypCd, //필컨만 : 17, 마동만 : 19, 필컨&마동 : 18
              custNm: this.pCustNm
            }
          }
        )
      },
      /******************************************************************************
      * Function명: _Back
      * 설명: 헤더 영역 Back Button 파라미터 넘기기
      *          >>>> 2차 개발중 요건변경으로 사용안함
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      _Back () {
        return
        let alertMsg = '고객동의를 종료하시겠습니까?<br/> 동의종료 선택시 작성된 고객정보는 저장되지 않으며, 고객동의는 완료되지 않습니다.'
        let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
        let preSrnId = !this.$bizUtil.isEmpty(preSrnObj.srnId) ? preSrnObj.srnId : 'MSPBC002M'

        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            content: alertMsg,
            title_pos_btn: '나가기'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj)
              
              // backButton event 해제
              window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, lv_Vm.fn_Back)

              window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', {}) // 진입전 정보 초기화
              this.$router.push({ name: preSrnId, params: preSrnObj.rtnParam })
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertObj)
            }
          }
        })

      },
    } //methods end
  };
</script>
<style scoped>
</style>